import React, {useEffect, useState} from 'react';
import ChatContainer from './chat/ChatContainer';
import './App.css';
import Avatar from "./ai/Avatar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faUser, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import InitialModal from './InitialModal'; // Importa il modal

function App() {
    const [avatarBool, setAvatarBool] = useState(true);
    const [messageBool, setMessageBool] = useState(true);

    const [avatarId, setAvatarId] = useState("");
    const [voiceId, setVoiceId] = useState("");
    
    const [viewState, setViewState] = useState(2); // 0: chat e avatar, 1: solo avatar, 2: solo chat
    const [showModal, setShowModal] = useState(true); // Stato per il modal

    useEffect(() => {
        toggleViewState();
        
    }, [avatarBool, messageBool]);
    
    // Function to toggle view state
    const toggleViewState = () => {
        if(avatarBool && messageBool){
            setViewState((prevState) => (prevState + 1) % 3);
        }else if(!avatarBool && messageBool){
            setViewState(2);
        }else if(avatarBool && !messageBool){
            setViewState(1);
        }

        let key = "stop";
        let value = 1;
        localStorage.setItem(key, value);
        window.dispatchEvent(new CustomEvent('localStorageChanged', { detail: { key, value } }));
    };

    // Function to close the modal
    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className="lavender h-screen flex flex-col md:flex-row">
            <InitialModal show={showModal} onClose={handleCloseModal} />

            <div
                className={`avatar-container ${
                    viewState === 0 ? 'md:w-1/3 w-full h-1/3 md:h-full' : viewState === 1 ? 'h-screen fullscreen-mobile' : 'w h-0 hidden'
                } transition-all duration-300 overflow-hidden flex items-center justify-center air-force-blue`}>

                {(viewState === 0 || viewState === 1) && <Avatar viewState={viewState} avatarId={avatarId} voiceId={voiceId} />}
            </div>

            <div className={`flex-1 transition-all duration-300 ${viewState === 2 ? 'w-full' : ''}`}>
                <ChatContainer viewState={viewState} setAvatarBool={setAvatarBool} setMessageBool={setMessageBool} setAvatarId={setAvatarId} setVoiceId={setVoiceId} />
            </div>

            {avatarBool && messageBool && (
                <button
                    onClick={toggleViewState}
                    className="pulsante-mobile-1"
                    aria-label={viewState === 0 ? 'Show Only Avatar' : viewState === 1 ? 'Show Only Chat' : 'Show Chat and Avatar'}
                >
                    <FontAwesomeIcon icon={viewState === 0 ? faArrowDown : viewState === 1 ? faArrowUp : faUser} />
                </button>
            )}
        </div>
    );
}

export default App;