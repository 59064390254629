import React, { useEffect, useRef, useState } from 'react';
import 'boxicons';
import { Configuration, StreamingAvatarApi } from '@heygen/streaming-avatar';
import axios from "axios";
//import ChromaKeyVideo from './ChromaKeyVideo';
import { DNA } from 'react-loader-spinner';  // Importa il loader

function Avatar({ viewState, avatarId, voiceId }) {

    var accessToken = "";

    const [stream, setStream] = useState(null);
    const [debug, setDebug] = useState('');
    const [data, setData] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const mediaStream = useRef(null);
    const avatar = useRef(null);

    const [display, setDisplay] = useState('avatar');

    const [loading, setLoading] = useState(false);  // Stato per gestire il caricamento

    let sessione = null;

    useEffect(() => {
        const handleFirstClick = () => {
            setLoading(true);

            if (avatarId !== "" && voiceId !== "") {
                fetchData();  // Chiama fetchData solo se avatarId e voiceId non sono vuoti
            } else {
                console.log("avatarId o voiceId sono vuoti");
            }
    
            window.removeEventListener('click', handleFirstClick);  // Rimuovi l'evento dopo il primo click
        };
    
        window.addEventListener('click', handleFirstClick);
    
        return () => {
            window.removeEventListener('click', handleFirstClick);  // Pulisci l'evento
        };
    }, [avatarId, voiceId]);

    //setup token iniziale
    const fetchData = async () => {
        try {
            const response = await axios.get(
                'https://api-ai.rgweb.it/api/avatar/token',
                {
                    headers: {
                        'accept': 'application/json'
                    }
                }
            );

            const token = response.data.token;

            console.log("fetchData: ", token);
            accessToken = token;

            updateLocalStorage('start', "1");
        } catch (error) {
            console.error('Error fetching the token:', error);
        }
    };

    //aggiorna token
    async function updateToken() {
        console.log("accessToken: ", accessToken);

        avatar.current = new StreamingAvatarApi(
            new Configuration({ accessToken: accessToken })
        );

        const startTalkCallback = (e) => {
            console.log('Avatar started talking', e);
            setDebug('Avatar started talking: ' + JSON.stringify(e));
        };

        const stopTalkCallback = (e) => {
            console.log('Avatar stopped talking', e);
            setDebug('Avatar stopped talking: ' + JSON.stringify(e));
        };

        console.log('Adding event handlers:', avatar.current);
        setDebug('Adding event handlers: ' + JSON.stringify(avatar.current));
        avatar.current.addEventHandler('avatar_start_talking', startTalkCallback);
        avatar.current.addEventHandler('avatar_stop_talking', stopTalkCallback);

        setInitialized(true);
    }

    //setup per parlare
    async function grab() {
        await updateToken();

        if (!avatar.current) {
            setDebug('Avatar API is not initialized');
            return;
        }

        try {
            const res = await avatar.current.createStartAvatar(
                {
                    newSessionRequest: {
                        quality: 'high',
                        avatarName: avatarId,
                        voice: {
                            voiceId: voiceId,
                            rate: 1,
                        }
                    }
                }
            );

            console.log("grab res: ", res);
            setData(res);
            sessione = res;
            console.log("data: ", data);
            setStream(avatar.current.mediaStream);

            setLoading(false);  // Imposta loading a false quando il caricamento finisce
        } catch (error) {
            console.error('Error starting avatar session:', error);
        }
    }

    //stop parlare
    async function stop() {
        if(sessione == null){
            return;
        }

        await avatar.current.stopAvatar(
            { stopSessionRequest: { sessionId: sessione.sessionId } },
            setDebug
        );

        fetchData();
    }

    //gestione eventi
    useEffect(() => {
        const handleStorageChange = async (event) => {
            let key = event.detail.key;
            let value = event.detail.value;

            if (key == 'start' && value == "1") {
                localStorage.setItem('start', "0");

                await grab();
            }
            if (key == 'stop' && value == "1") {
                localStorage.setItem('stop', "0");

                stop();
            }

            if (key == 'startSpeak' && value != "") {
                localStorage.setItem('startSpeak', "");

                let toSpeak = value;
                let startIndex = 0;

                //sanificazione frase
                toSpeak = toSpeak.replace(/(<([^>]+)>)/gi, "");

                // Sostituisci "rgweb" con "ERRE-GI-WEB" (no case sensitive)
                toSpeak = toSpeak.replace(/rgweb/gi, "ERRE-GI-WEB");

                while (startIndex < toSpeak.length) {
                    // Trova l'indice di fine del chunk
                    let endIndex = startIndex + 900;

                    // Assicurati di non tagliare le parole
                    if (endIndex < toSpeak.length && toSpeak[endIndex] !== ' ') {
                        let lastSpaceIndex = toSpeak.lastIndexOf(' ', endIndex);
                        if (lastSpaceIndex > startIndex) {
                            endIndex = lastSpaceIndex;
                        }
                    }

                    // Ottieni il chunk
                    const chunk = toSpeak.slice(startIndex, endIndex);

                    //parla
                    handleSpeak(chunk);

                    // Aggiorna l'indice di inizio
                    startIndex = endIndex + 1;

                    // Pausa di 1 secondi
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
            }

            if(key == 'stopSpeaking' && value == 1){
                localStorage.setItem('stopSpeaking', "0");

                await avatar.current.interrupt(
                    { interruptRequest: { sessionId: sessione.sessionId } },
                    setDebug
                );

            }
        };

        window.addEventListener('localStorageChanged', handleStorageChange);

        return () => {
            window.removeEventListener('localStorageChanged', handleStorageChange);
        };
    }, [avatarId, voiceId]);

    async function handleSpeak(textToSpeak) {
        if(sessione == null){
            return;
        }
        
        await avatar.current
            .speak({ taskRequest: { text: textToSpeak, sessionId: sessione.sessionId } })
            .catch((e) => {
                setDebug(JSON.stringify(e));
            });
    }

    useEffect(() => {
        if (stream && mediaStream.current) {
            mediaStream.current.srcObject = stream;
            mediaStream.current.onloadedmetadata = () => {
                mediaStream.current.play();
                mediaStream.current.volume = 1.0;

                setDebug('Playing');
            };
        }
    }, [mediaStream, stream]);


    useEffect(() => {
        localStorage.setItem('debug', debug);
    }, [debug]);

    //scrivi nel localSTORAGE
    function updateLocalStorage(key, value) {
        localStorage.setItem(key, value);
        // Dispatch a custom event to notify about the change
        window.dispatchEvent(new CustomEvent('localStorageChanged', { detail: { key, value } }));
    }


    return (
        <div className="App">
            {loading && !viewState && (
                <div className="loader-container">
                    <DNA  type="ThreeDots" color="#00BFFF" height={80} width={80} />
                </div>
            )}
            <div className={viewState === 1 ? "MediaPlayer1" : "MediaPlayer"}>
                <video playsInline autoPlay ref={mediaStream} style={{
                    borderTopLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                    display: (display === 'avatar') ? 'block' : 'none',
                }}></video>
            </div>
        </div>
    );
}

export default Avatar;
