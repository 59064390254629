import React, { useEffect } from 'react';

function InitialModal({ show, onClose }) {
    useEffect(() => {
        // Prevent scrolling when modal is open
        if (show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [show]);

    if (!show) {
        return null;
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 modal">
            <div className="bg-white rounded-lg p-8 shadow-lg max-w-sm w-full text-center">
                <h2 className="text-2xl font-semibold mb-4">Benvenuto!</h2>
                <p className="mb-4">Riceverai assistenza dal nostro assistente virtuale!</p>
                <button
                    onClick={onClose}
                    className="px-4 py-2 bg-blue-600 text-white rounded-md"
                >
                    Avanti
                </button>
            </div>
        </div>
    );
}

export default InitialModal;
