import React, {useEffect, useState} from 'react';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

function ChatInput({handleSendMessage, isTyping}) {

    const [message, setMessage] = useState('');

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        finalTranscript
    } = useSpeechRecognition();

    if(!browserSupportsSpeechRecognition)
        alert("Non supportato!");

    useEffect(() => {
        if(finalTranscript != ''){
            handleSendMessage(message);
            setMessage('');
            resetTranscript();
        }
    }, [finalTranscript]);

    useEffect(() => {
        if(transcript != ''){
            setMessage(transcript);
        }
    }, [transcript]);


    //per aggiornare il messagio ogni volta che scrivi
    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    //per inviare premento l'invio della tastiera
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage(message);
            setMessage(''); // Pulisci l'input dopo l'invio del messaggio
        }
    };

    const prehandleSendMessage = (message) => {
        handleSendMessage(message);
        setMessage('');
    }

    return (
    <div className="flex items-center mt-2 mr-1 ml-1 gap-1">
      <input
          type="text"
          placeholder="Scrivi un messaggio..."
          className="flex-1 p-2 rounded-lg"
          value={message}
          onChange={handleMessageChange}
          onKeyPress={handleKeyPress}
      />
      <button className={`p-2 ${listening ? 'listening' : 'cool-gray'} rounded-xl`} onClick={SpeechRecognition.startListening}><i className="fas fa-microphone"></i></button>
      <button className="p-2 cool-gray rounded-xl" onClick={() => prehandleSendMessage(message)}>
          {isTyping ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-paper-plane"></i>}
      </button>
    </div>
    );
}

export default ChatInput;