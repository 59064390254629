import React, {useEffect, useRef, useState} from 'react';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';
import TypingIndicator from './TypingIndicator';
import ChatButtons from './ChatButtons';

function ChatContainer({ viewState, setAvatarBool, setMessageBool, setAvatarId, setVoiceId }) {

  //array che conterrà tutta la chat e inizializzato con un messaggio
  const [chat, setChat] = useState([]);

  // Stato per gestire il "typing indicator"
  const [isTyping, setIsTyping] = useState(false);

  // Crea un riferimento al container della chat
  const chatContainerRef = useRef(null);

  //ottieni codiceApi
  useEffect(() => {
    // Resetta il localStorage al caricamento della pagina
    localStorage.clear();

    localStorage.setItem("lunghezzaChat", "1");
    localStorage.setItem("lunghezzaChatOra", "1");

    const fetchData = async () => {
      var localKey = localStorage.getItem("localKey");

      if (localKey == undefined || localKey == null) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          "api_key": new URLSearchParams(window.location.search).get("token")
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
        };

        try {
          const response = await fetch("https://api-ai.rgweb.it/api/chat", requestOptions);
          const result = await response.json();

          if (result.id) {
            localStorage.setItem("localKey", result.id);
            setChat(prevChat => [...prevChat, { position: "left", message: result.message , buttons: []}]);

            setAvatarBool(result.avatar);
            setMessageBool(result.chat);
            setAvatarId(result.avatar_id);
            setVoiceId(result.voice_id);
          }
        } catch (error) {
          console.error('Errore durante la richiesta:', error);
        }
      }
    };

    fetchData();
  }, []);

  //funzione per aggiungere messaggi all'array della chat
  const addMessage = (position, message, buttons = []) => {
    if(position === "right"){
      setIsTyping(true); // Mostra il "typing indicator" quando l'utente invia un messaggio
    }

    if(buttons.length > 0){
      var buttonArray;

      if(buttons.indexOf("_") != -1){
        buttonArray = buttons.split("_");
      }else{
        buttonArray = buttons.split(",");
      }
    }else{
      buttonArray = buttons;
    }

    setChat(prevChat => [...prevChat, { position: position, message: message , buttons: buttonArray}]);

    localStorage.setItem("lunghezzaChatOra", parseInt(localStorage.getItem("lunghezzaChat"))+1);
  };

  //funzione per ricevere i messaggi
  useEffect( () => {
    const interval = setInterval(async () => {

      let lunghezzaChatOra = localStorage.getItem("lunghezzaChatOra");
      if(lunghezzaChatOra == localStorage.getItem("lunghezzaChat")){
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      let localKey = localStorage.getItem("localKey");

      try {
        const response = await fetch("https://api-ai.rgweb.it/api/chat/" + localKey + "/messages", requestOptions);
        const result = await response.json();

        const lastMessageId = localStorage.getItem("lastMessageId");
        const lastMessage = result[result.length - 1];

        //rimozione 【source】.
        //result[result.length - 1].text = (result[result.length - 1].text).replace(/【.*?】\.?$/, "");

        //rimozione 【source】
        result[result.length - 1].text = (result[result.length - 1].text).replace(/【.*?】/g, "");

        //rimozione . finale
        result[result.length - 1].text = (result[result.length - 1].text).replace(/\.$/, "");


        if((lastMessageId == undefined || lastMessageId == null || lastMessageId == "") && result.length > 0) { 
          if(result[result.length - 1].from == "assistant"){ 
            if(lastMessage.image_url != "" && lastMessage.image_url != null){
              let img = `<img src='` + lastMessage.image_url + `' style='width: 50vh; height: auto;'>`;
  
              addMessage("left", img, []);
            }

            addMessage("left", result[result.length - 1].text, result[result.length - 1].buttons); 
            localStorage.setItem("lastMessageId", result[result.length - 1].id); 

            localStorage.setItem("lunghezzaChat", lunghezzaChatOra); 

            setIsTyping(false); // Nascondi il "typing indicator" dopo aver ricevuto la risposta 

            updateLocalStorage('startSpeak', result[result.length - 1].text); 
          } 
        } 

        if(lastMessageId != undefined || lastMessageId != null){ 
          if(lastMessageId != result[result.length - 1].id && result[result.length - 1].from == "assistant"){ 
            if(lastMessage.image_url != "" && lastMessage.image_url != null){
              let img = `<img src='` + lastMessage.image_url + `' style='width: 50vh; height: auto;'>`;
  
              addMessage("left", img, []);
            }

            addMessage("left", result[result.length - 1].text, result[result.length - 1].buttons); 
            localStorage.setItem("lastMessageId", result[result.length - 1].id); 

            localStorage.setItem("lunghezzaChat", lunghezzaChatOra); 

            setIsTyping(false); // Nascondi il "typing indicator" dopo aver ricevuto la risposta 

            updateLocalStorage('startSpeak', result[result.length - 1].text); 
          } 
        }
      } catch (error) {
        console.error('Errore durante la richiesta:', error);
      }
    }, 1500);

    return () => clearInterval(interval); // Pulisci l'intervallo quando il componente viene smontato
  }, []);

  //funzione per scollare in fondo alla chat
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chat]);

  //scrivi nel localSTORAGE
  function updateLocalStorage(key, value) {
    localStorage.setItem(key, value);
    // Dispatch a custom event to notify about the change
    window.dispatchEvent(new CustomEvent('localStorageChanged', { detail: { key, value } }));
  }

  //per inviare il messaggio
  const handleSendMessage = async (message) => {
    if (message.trim() !== '') {
      
      let debug = localStorage.getItem("debug");
      if(debug.includes("Avatar started talking")){
        updateLocalStorage("stopSpeaking", 1);
      }

      addMessage("right", message);
      let tempMessage = message;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "message": tempMessage
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      let localKey = localStorage.getItem("localKey");
      const response = await fetch("https://api-ai.rgweb.it/api/chat/" + localKey + "/messages", requestOptions)
          .then((response) => response.text())
          .then((result) => {
            console.log(result);
            this.inputRef.current.value = ''; // Pulisci l'input dopo l'invio del messaggio
          })
          .catch((error) => console.error(error));
    }
  };


  //stampa la chat (.map = foreach)
  return (
      <div className={`w-full h-full flex flex-col justify-between p-4 transition-all duration-300`}>
        <div className={`flex-1 overflow-y-auto p-4 ${viewState !== 1 ? '' : 'chat2mobile'} chat-container ${viewState === 2 ? 'chat-container-full' : ''}`} ref={chatContainerRef}>
          {chat.map((msg, index) => (
              <React.Fragment key={index}>
                <ChatMessage position={msg.position} message={msg.message} />
                {msg.buttons.length > 0 && (
                    <ChatButtons
                        buttons={msg.buttons}
                        handleSendMessage={handleSendMessage}
                    />
                )}
              </React.Fragment>
          ))}
          {isTyping && <TypingIndicator />}
        </div>

        <ChatInput handleSendMessage={handleSendMessage} isTyping={isTyping} />
      </div>
  );
}

export default ChatContainer;
